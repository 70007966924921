@import url('https://fonts.googleapis.com/css2?family=Noto+Sans:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Noto+Sans+Arabic:wght@100;200;300;400;500;600;700;800;900&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Amiri:wght@400;700&display=swap');

@tailwind base;
@tailwind components;
@tailwind utilities;

html,
body,
#root,
.app {
    height: 100%;
    @apply text-white;
}

@font-face {
  font-family: "ReemKufi";
  src: url("../public/AmiriRegular.ttf");
}

@font-face {
  font-family: "NotoSansSymbol";
  src: url("../public/NotoSansSymbols.ttf");
}

@layer base {
  .shadow-link {
    background-image: linear-gradient(to top, #D1AB29 , #E5C973, transparent);
    background-position: 0 -5px;
    background-repeat: no-repeat;
    background-size: auto 3rem;
  }
}

